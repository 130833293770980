import { Form, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/nl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { MdOutlineTimer } from 'react-icons/md';
import 'react-phone-input-2/lib/style.css';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import './App.scss';
import NewFreemium from './components/NewFreemium';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import StepAlreadyConfirmed from './components/StepAlreadyConfirmed';
import StepAlreadyExists from './components/StepAlreadyExists';
import StepEmployee from './components/StepEmployee';
import StepLoading from './components/StepLoading';
import StepPlanCard from './components/StepPlanCard';
import StepPlanDetails from './components/StepPlanDetails';
import StepPlans from './components/StepPlans';
import './styles/antdesign.less';
import colors from './styles/colors';

interface Props {
  className?: string;
}

const App: React.FC<Props> = ({ className }) => {
  const { i18n, t } = useTranslation(undefined, { useSuspense: true });
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState<any | null>(null);
  const [form] = Form.useForm();
  const { lang = 'en' } = useParams<any>();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const type = params.get('type');
  const uuid = params.get('uuid');
  const [activeStep, setActiveStep] = useState('first');

  useEffect(() => {
    if (!uuid) return;
    setActiveStep('plans');
  }, [uuid]);

  const plans = [
    {
      id: '1',
      className: 'clocking',
      headIcon: <MdOutlineTimer className="head-icon" />,
      title: t('PLANS.CLOCKING.TITLE'),
      description: t('PLANS.CLOCKING.DESCRIPTION'),
      options: [t('PLANS.CLOCKING.OPTIONS.1'), t('PLANS.CLOCKING.OPTIONS.2'), t('PLANS.CLOCKING.OPTIONS.3')],
      price: 39,
    },
    {
      id: '2',
      className: 'planning',
      headIcon: <FaRegCalendarAlt className="head-icon" />,
      title: t('PLANS.PLANNING.TITLE'),
      description: t('PLANS.PLANNING.DESCRIPTION'),
      options: [t('PLANS.PLANNING.OPTIONS.1'), t('PLANS.PLANNING.OPTIONS.2'), t('PLANS.PLANNING.OPTIONS.3')],
      price: 39,
    },
    {
      id: '3',
      className: 'full',
      headIcon: <FaRegCalendarAlt className="head-icon" />,
      title: t('PLANS.FULL.TITLE'),
      description: t('PLANS.FULL.DESCRIPTION'),
      options: [t('PLANS.FULL.OPTIONS.1')],
      price: 54,
    },
  ];

  useEffect(() => {
    if (!type || type !== 'clocking') return;
    setActiveStep('account');
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
    moment.locale(lang, {
      week: {
        dow: 1,
      },
    });
  }, [location, lang]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('try-account');
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const onFinish = (values: any) => {
    handleReCaptchaVerify();
    setIsSaving(true);
    setActiveStep('new_trial');

    const hubspotutk = getCookie('hubspotutk');
    const cid = new URLSearchParams(search).get('cid');

    axios
      .post(`${process.env.REACT_APP_API_URL}/operations/create/trial`, {
        ...values,
        lang,
        hubspotutk,
        cid,
        need:
          params && params.get('type') == 'clocking'
            ? 'clocking'
            : Array.isArray(values.need)
            ? values.need[0]
            : values.need,
        feature: 'clocking',
      })
      .then(({ data }) => {
        setIsSaving(false);
        setData(data);
        TagManager.dataLayer({
          dataLayer: {
            event: 'new_lead',
          },
          dataLayerName: 'PageDataLayer',
        });
      })
      .catch((error) => {
        setIsSaving(false);
        console.log(error);
        message.error(t('ACCOUNT_CREATION_ERROR'));
        if (error.response?.status === 403) {
          form.setFields([
            {
              name: 'email',
              errors: [t('STEP_2.EMAIL_ERROR')],
            },
          ]);
          setActiveStep('account');
        } else {
          setActiveStep('company');
        }
      });
  };

  const steps = useMemo(() => {
    return [
      {
        key: 'first',
        content: <Step1 onNext={() => setActiveStep('account')} onNextPerson={() => setActiveStep('employee')} />,
      },
      {
        key: 'employee',
        content: <StepEmployee onPrevious={() => setActiveStep('first')} />,
      },
      {
        key: 'already-exists',
        content: <StepAlreadyExists />,
      },
      {
        key: 'account',
        content: (
          <Step2
            form={form}
            onPrevious={() => setActiveStep('first')}
            onNext={(setCheckingEmail: React.Dispatch<React.SetStateAction<boolean>>) => {
              setCheckingEmail(false);
              setActiveStep('company');
            }}
          />
        ),
      },
      {
        key: 'company',
        content: <Step3 onPrevious={() => setActiveStep('account')} form={form} isSaving={isSaving} />,
      },
      {
        key: 'new_trial',
        content: <NewFreemium />,
      },
      {
        key: 'plans',
        content: <StepPlans plans={plans} setActiveStep={setActiveStep} uuid={uuid} />,
      },
      {
        key: 'plan_details',
        content: <StepPlanDetails plans={plans} setActiveStep={setActiveStep} uuid={uuid} />,
      },
      {
        key: 'plan_card',
        content: <StepPlanCard setActiveStep={setActiveStep} uuid={uuid} />,
      },
      {
        key: 'already_confirmed',
        content: <StepAlreadyConfirmed />,
      },
    ];
  }, []);

  return (
    <div className={`dual-page ${className}`}>
      <div className="left" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/img/tryme.jpg'})` }}>
        <img className="logo" src={process.env.PUBLIC_URL + '/img/shyfter-white.svg'} alt="Shyfter" />
        <h2>{t('BASELINE')}</h2>
        <span className="author">Picture @codydoherty</span>
      </div>
      <div className="right">
        <Form form={form} size="large" layout="vertical" onFinish={onFinish}>
          {steps?.map((step) => {
            const { content, key } = step;
            return (
              <div key={`step_${key}`} className={`steps ${key === activeStep ? 'fade-in' : ''}`}>
                {content}
              </div>
            );
          })}
          {activeStep === 'loading' && <StepLoading setActiveStep={setActiveStep} />}
        </Form>
      </div>
    </div>
  );
};

export default styled(App)`
  .left {
    .logo {
      position: absolute;
      left: 0;
      top: 0;
      width: 128px;
      height: auto;
      margin: 20px;
    }
    .author {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 20px;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .right {
    form {
      margin: 0;

      .steps {
        display: none;
        opacity: 0;
        animation: fade-out 1s 1;
        &.fade-in {
          display: block;
          opacity: 1;
          animation: fade-in 0.5s 1;
        }
        h1 {
          font-style: normal;
          font-weight: bold;
          font-size: 34px;
          line-height: 38px;
        }
        h2 {
          margin-top: 25px;
          margin-bottom: 25px;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 24px;
          color: ${colors.green};
        }
      }
      .fields {
        .forgot-password {
          position: absolute;
          right: 0;
          color: ${colors.green};
          text-decoration: underline;
          z-index: 2;
        }
        label.ant-checkbox-wrapper {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }
`;

function getCookie(name: string): string | null {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
